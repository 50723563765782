#body{
margin:0;
padding:0;
}

// ********************
// *** MEDIA MIXINS ***
// ********************
$reallyNarrowScreens: 321;
@mixin tinyPhone {
  @media only screen and (max-width: #{$reallyNarrowScreens - 1px}) {
    @content;
  }
}


// less than 479, i.e. small phone screen
@mixin phone {
  @media only screen and (max-width: #{$screen-xs - 1px}) {
    @content;
  }
}

@mixin iphoneX {
  @media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
    @content;
  }
}

// between 480 and 767pixels
@mixin smallTablet {
  @media (min-width: #{$screen-xs}) and (max-width: #{$screen-sm - 1px}) {
    @content;
  }
}

// between 768 pixels and 991 pixels, i.e. small tablet
@mixin tablet {
  @media (min-width: #{$screen-sm}) and (max-width: #{$screen-md - 1px}) {
    @content;
  }
}

// between 992 and 1199 pixels, i.e. tablet and medium screens
@mixin laptop {
  @media (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1px}) {
    @content;
  }
}

// large screens only
@mixin largeScreen {
  @media (min-width: #{$screen-lg}) and (max-width: #{$screenExtraLg - 1px}) {
    @content;
  }
}

@mixin extraLargeScreen {
  @media (min-width: #{$screenExtraLg}) {
    @content;
  }
}

// above recipe comes from https://davidwalsh.name/write-media-queries-sass


// ***************************
// *** PADDING AND BORDERS ***
// ***************************

$animBannerHeight: 100px;
$gutterLarge: 40px;
$gutterMedium: 20px;
$gutterSmall: 10px;
$gutterExtraSmall: 5px;


// ************************
// *** COLOUR VARIABLES ***
// ************************

// *** BOOTSTRAP VARIABLES ***
// override the bootstrap variables below
$fortCyan: #3fa9f5; // fort sky
$fortPurple: #433a6c; // fort purple
$fortRed: #d5443a; // fort red
$fortWhite: #ffffff; // fort white
$fortBlack: #333333;
$fortAqua: #3f7ca4;
$fortGrey: #eeeeee;

$screenExtraLg: 1800px;

$lineHeightOverview: 1.7;
$lineHeightCards: 1.4;


// ****************************
// *** FONT SIZES & WEIGHTS ***
// ****************************

// jumboH1
$jumboH1Mob: ceil(($font-size-base * 2.7));
$jumboH1: ceil(($font-size-base * 3.5));
$jumboH1ExtraLarge: ceil(($font-size-base * 4.5));
$jumboH1Weight: 700;

// jumboH2
$jumboH2: ceil(($font-size-base * 2));
$jumboH2SmallTablet: ceil(($font-size-base * 1.6));
$jumboH2MediumLarge: ceil(($font-size-base * 3));
$jumboH2ExtraLarge: ceil(($font-size-base * 4.2));
$jumboH2Weight: 700;

// jumbo para
$jumboPara: ceil(($font-size-base * 1.3));
$jumboParaSmallTablet: ceil(($font-size-base * 1));
$jumboParaExtraLarge: ceil(($font-size-base * 2));
$jumboParaWeight: 100;

// over image para
$paraBlockWeight: 300;

// side menu
$sideNavLink: ceil(($font-size-base * 1.3));
$sideBarFontWeight: 200;

// contact number and email
$contactPara: ceil(($font-size-base * 1.4));
$contactParaExtraLargeScreen: ceil(($font-size-base * 2));
$contactParaWeight: 400;

$paraSmall: ceil(($font-size-base * 0.8));
$paraSmallExtraLargeScreen: ceil(($font-size-base * 1.4));
$paraSmallWeight: 300;

// secondary nav - articles and landing pages
$secNavHeaderSize: ceil(($font-size-base * 1.2));
$secNavHeaderWeight: 700;
$secNavLinksSize: ceil(($font-size-base * 1));
$secNavLinksWeight: 400;

// article headers and paras
$articleHeaderSize: ceil(($font-size-base * 1.6));
$articleHeaderWeight: 700;

$articleH3: ceil(($font-size-base * 1.4));
$articleH4: ceil(($font-size-base * 1.2));
$articleH5: ceil(($font-size-base * 1.1));

$articleParasize: ceil(($font-size-base * 1.1));
$articleParaWeight: 200;

$articleBlockQuoteFontSize: ceil(($font-size-base * 1.4));
$articleBlockQuoteFontWeight: 300;

// case study sizes
$caseStudyHeaderSize: ceil(($font-size-base * 1.4));
$caseStudyHeaderWeight: 700;
$caseStudyParaSize: ceil(($font-size-base * 0.9));
$caseStudyParaWeight: 200;
$caseStudyLinkWeight: 700;

// social media author
$authorText: ceil(($font-size-base * 0.9));
$authorTextWeight: 200;
$authorBold: 700;

.h2Blue {
  color: $fortCyan;
  font-size: ceil(($font-size-base * 2.6))!important;
  font-weight: 700;
  text-align: center;
  margin-top: $gutterSmall;
  line-height: 1.3em;
  @include largeScreen {
    font-size: ceil(($font-size-base * 3))!important;
  }
  @include extraLargeScreen {
    font-size: ceil(($font-size-base * 4.2))!important;
  }

}

.pOverview {
  // line-height: ;
  text-align: center;
  font-size: ceil(($font-size-base * 1.3))!important;
  font-weight: 100;
  color: $fortBlack;
  @include phone {
    font-size: ceil(($font-size-base * 1.4))!important;
    font-weight: 200;
    line-height: $lineHeightOverview!important;
  }
}

.h3CardHeader {
  color:$fortPurple;
  font-size: ceil(($font-size-base * 1.2))!important;
  text-align: center;
  font-weight: 700;
  @include phone {
    font-size: 19px!important;
  }

}

.pCardDescription {
  color: $fortBlack;
  font-size: ceil(($font-size-base * 1))!important;
  font-weight: 100;
  text-align: center;

  a {
    font-weight: 400;
  }

  @include phone {
    font-size: 16px!important;
    font-weight: 200!important;
    line-height: $lineHeightCards!important;
  }
  @include smallTablet {
    font-size: 16px!important;
    font-weight: 200!important;
    line-height: $lineHeightCards!important;
  }
  @include tablet {
    font-size: 16px!important;
    font-weight: 200!important;
    line-height: $lineHeightCards!important;
  }
}


// ***************
// *** BUTTONS ***
// ***************

.btn-fort-red {
  @include button-variant($fortWhite, $fortRed, $fortRed);

  //@include phone {
    //@include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
  //}
}

.btn-fort-blue-lg {

  @include button-variant($fortWhite, $fortPurple, $fortPurple);
  @include button-size($gutterExtraSmall, $gutterExtraSmall, $contactPara, $line-height-small, $btn-border-radius-base);

}



// *******************************
// *** POSITIONING & FUNCTIONS ***
// *******************************

// aligns images and text to the right, centre or left
.alignRight {
  text-align: right;
}

.alignLeft {
  text-align: left;
}

.alignCentre {
  text-align: center;
}

// mixin for styling blocks with large background image
@mixin fortBlockStyle ($fortColor, $headingSize, $headingWeight, $paraSize, $paraWeight, $paraColor, $alignment, $multipleCols) {

  // set color for fonts
  color: $fortColor;

  // set heading size
  h1, h2, h3, h4, h5, h6 {
    font-size: $headingSize;
    font-weight: $headingWeight;
  }

  // set paragraph size and weight only of immediate children of div
  div > p {
    font-size: $paraSize;
    font-weight: $paraWeight;
    color: $paraColor;
  }

  @include smallTablet {
    h1, h2, h3, h4, h5, h6 {
      font-size: $jumboH2SmallTablet;
      font-weight: $headingWeight;
    }
    div > p {
      font-size: $jumboParaSmallTablet;
      font-weight: $paraWeight;
    }
  }

  @include largeScreen {
    h1, h2, h3, h4, h5, h6 {
      font-size: $jumboH2MediumLarge;
      font-weight: $headingWeight;
    }
    div > p {
      font-size: $paraSize;
      font-weight: $paraWeight;
    }
  }

  @include extraLargeScreen {
    h1, h2, h3, h4, h5, h6 {
      font-size: $jumboH2ExtraLarge;
      font-weight: $headingWeight;
    }
    div > p {
      font-size: $jumboParaExtraLarge;
      font-weight: $paraWeight;
    }
  }

  // alignment
  text-align: $alignment;


  div {
    padding-top: $gutterLarge;
    @if $multipleCols == true {
      width: 100%;
    } @if $multipleCols == false {
      width: 50%;
    }


    @if $alignment == left {
      float: left;

    } @else if $alignment == right {
      float: right;

    } @else if $alignment == center {
      display: block;
      margin-left: auto;
      margin-right: auto;
      float: none!important;
    }

    @include phone {
      width: 100%;
      padding-top: $gutterSmall;
      img {
        width: 60px;
        height: 60px;
      }
    }
    @include smallTablet {
      padding-top: $gutterSmall;
      img {
        width: 60px;
        height: 60px;
      }
    }
    @include tablet {
      padding-top: $gutterSmall;
      img {
        width: 70px;
        height: 70px;
      }
    }
  }

}


// float left right or center, apply to cols
@mixin alignmentMixin($alignment) {
  text-align: $alignment;
}

.centerIcons {
  // use for list-inline, apply to the ul
  li {
    vertical-align: middle;
  }
}

.extraPadding {
  padding-top: $gutterMedium;
}


// ********************
// *** TOP PARALLAX ***
// ********************

#topParallaxWrapper {

  height: 650px;
  position: absolute;
  width: 100%;
  top: 0;

  @include phone {
    //background-image: url("../images/design-assets/hero-home-small.svg");
    background-size: 100% auto;
  }

  /*@include iphoneX {
    background-image: url("../images/design-assets/hero-home-small.svg");
    background-size: 100% auto;
  }*/

  @include smallTablet {
    //background-image: url("../images/design-assets/hero-home.svg");
    background-size: 100% auto;
  }

  @include tablet {
    //background-image: url("../images/design-assets/hero-home.svg");
    background-size: 100% auto;
  }

  @include laptop {
    //background-image: url("../images/design-assets/hero-home-wide.svg");
    background-size: 100% auto;
  }

  @include largeScreen {
    //background-image: url("../images/design-assets/hero-home-wide.svg");
    background-size: 100% auto;
  }

  @include extraLargeScreen {
    //background-image: url("../images/design-assets/hero-home-wide.svg");
    background-size: 100% auto;
  }

  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-color: $fortPurple;

}

// ****************************
// *** JUMBOTRON TOP BANNER ***
// ****************************

/*.jumboTronTop {
  // positioning
  position: absolute;
  top: 80px;
}*/


.jumbotron1 {

  padding-top:    $jumbotron-padding;
  padding-bottom: $jumbotron-padding;
  margin-bottom: $jumbotron-padding;
  color: $jumbotron-color;
  background-color: transparent;
  height: auto;

  @include phone {
    h1,
    .h1 {
      font-size: $jumboH1Mob;
    }

    p {
      font-size: $jumboPara;
    }

    text-align: center;

  }

  @include smallTablet {

    h1,
    .h1 {
      font-size: $jumboH2SmallTablet;
    }

    p {
      font-size: $jumboParaSmallTablet;
    }

  }

  @include tablet {

    h1,
    .h1 {
      font-size: $jumboH1Mob;
    }

    p {
      font-size: $jumboPara;
    }

  }

  @include laptop {
    h1,
    .h1 {
      font-size: $jumboH1;
    }

    p {
      font-size: $jumboPara;
    }
  }

  @include largeScreen {

    h1,
    .h1 {
      font-size: $jumboH1;
    }

    p {
      font-size: $jumboPara;
    }

  }

  @include extraLargeScreen {

    h1,
    .h1 {
      font-size: $jumboH1ExtraLarge;
    }

    p {
      font-size: $jumboParaExtraLarge;
    }
  }

  h1,
  .h1 {
    color: $fortWhite;
    font-weight: $jumboH1Weight;
  }

  p {
    margin-bottom: ($jumbotron-padding / 2);
    font-weight: $jumboParaWeight;
    color: $fortWhite;
  }


  > hr {
    border-top-color: darken($jumbotron-bg, 10%);
  }

  .container &,
  .container-fluid & {
    border-radius: $border-radius-large; // Only round corners at higher resolutions if contained in a container
    padding-left:  ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }

  .container {
    max-width: 100%;
  }

  @media screen and (min-width: $screen-sm-min) {
    padding-top:    ($jumbotron-padding * 1.6);
    padding-bottom: ($jumbotron-padding * 1.6);

    .container &,
    .container-fluid & {
      padding-left:  ($jumbotron-padding * 2);
      padding-right: ($jumbotron-padding * 2);
    }

    /*h1,
    .h1 {
      font-size: $jumboH1Mob;
    }*/
  }
}


// ********************************
// *** END JUMBOTRON TOP BANNER ***
// ********************************






// ***************************
// *** ANIMATED TOP BANNER ***
// ***************************

#topOuterBannerWrapper {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 0 0 0;
  z-index: 999; // 999
}

#animBanner {
  //content: "";
  background-color: $fortPurple;
  width: 100%;
  height: $animBannerHeight;
  opacity: 0;
}

// **************************
// *** LOGO & MENU BUTTON ***
// **************************

#outerNavWrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; // 1000
}

#logoWrapper {
  height: 100px;
}

#menuWrapper {
  height: 100px;
}

.logoSize {
  @include phone {
    width: 160px;
  }

  @include largeScreen {
    width: 230px;
  }
  width: 200px;
  height: auto;
  float: left;
}




// *** vertical alignment for the logo and the menu icon
// assign this to the div with the bootstrap row class
.vertical-align {
  display: flex;
  flex-direction: row;
}

// then this will target the descendant child columns
.vertical-align > [class^="col-"],
.vertical-align > [class*=" col-"] {
  display: flex;
  align-items: center;
  //justify-content: center;
  /* Optional, to align inner items
  horizontally inside the column */
}

// pull the menu item to the right
.menuRight {
  justify-content:flex-end;
}


// *******************************
// *** ANIMATED HAMBURGER MENU ***
// *******************************

.hamburger {
  position: relative;
  z-index: 3; // 3
}

.hamburger .line{
  @include phone {
    width: 30px;
    height: 3px;
  }
  width: 50px;
  height: 5px;
  background-color: #ecf0f1;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
}

/* ONE */

#hamburger-1.is-active .line:nth-child(2){
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1){
  @include phone {
    -webkit-transform: translateY(9px) rotate(45deg);
    -ms-transform: translateY(9px) rotate(45deg);
    -o-transform: translateY(9px) rotate(45deg);
    transform: translateY(9px) rotate(45deg);

  }
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3){

  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
}



// ***********************************
// *** END ANIMATED HAMBURGER MENU ***
// ***********************************



// ******************************
// *** END LOGO & MENU BUTTON ***
// ******************************

// *****************
// *** SIDE MENU ***
// *****************

/* The side navigation menu */
.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 2; /* Stay on top - was 1 */
    top: 0; /* Stay at the top */
    right: 0;
    background-color: $fortCyan; /* fort Cyan */
    overflow-x: hidden; /* Disable horizontal scroll */
    white-space: nowrap;
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    @include laptop {
      padding-top: 80px;
    }
    @include largeScreen {
      padding-top: 80px;
    }
    @include extraLargeScreen {
      padding-top: 100px;
    }
}

/* The navigation menu links */
.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: $sideNavLink;
    font-weight: $sideBarFontWeight;
    color: $fortWhite;
    display: block;
    transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
    color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 36px;
    margin-left: 50px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}

.menuIcon {
  width: 100%;
  padding: $gutterMedium;

  img {
    width: 60px;
    height: 60px;
    display: block;
  }

}


#overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7); /* Black background with opacity */
    z-index: 1; // 2 /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

// ************************
// *** SCROLL INDICATOR ***
// ************************

#scrollIndicator1 {
  opacity: 0;
}

.scrollIndicator {
  // padding: $gutterSmall;
  width: 60px;
  padding-top: $gutterMedium;
  padding-bottom: $gutterMedium;

  @include smallTablet {
    width: 60px;
  }

  @include tablet {
    width: 80px;
  }

  @include laptop {
    width: 80px;
  }

  @include largeScreen {
    width: 100px;
  }

  @include extraLargeScreen {
    width: 140px;
    padding-top: $gutterLarge;
  }
}


// ****************************
// *** END SCROLL INDICATOR ***
// ****************************

// *********************
// *** PRODUCT CARDS ***
// *********************

.productCard {
  margin-top: $gutterLarge;

}




// **********************
// *** MAIN CONTAINER ***
// **********************

#mainOuter {
  position: relative;
  //top: 90px;
  margin-top: 90px;
  width: 100%;
  height: auto;

}



// ****************************************
// *** HOMEPAGE / BIG HERO LANDING PAGE ***
// ****************************************


.block2 {

  width: 100%;
  height: 550px;


  // all media queries to be added to the html markup and reduced to just 3...
  /*@include phone {
    background-image: url("../images/design-assets/parallax-mob-castle.jpg");
  }

  @include smallTablet {
    background-image: url("../images/design-assets/parallax-sm-castle.jpg");
  }

  @include tablet {
    background-image: url("../images/design-assets/parallax-castle.jpg");
  }

  @include laptop {
    background-image: url("../images/design-assets/parallax-castle.jpg");
  }

  @include largeScreen {
    background-image: url("../images/design-assets/parallax-castle-large.jpg");
  }

  @include extraLargeScreen {
    background-image: url("../images/design-assets/parallax-castle-large.jpg");
  }*/


  // background-attachment: fixed;
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;

}

// block with repeating octagon
.block3 {

  width: 100%;
  height: 550px;
  //background-image: url("../images/design-assets/octagon-repeating.svg");

}

.block4 {

  width: 100%;
  height: 550px;


  // all media queries to be added to the html markup and reduced to just 3...
  /*@include phone {
    background-image: url("../images/design-assets/parallax-mob-marketing-energy.jpg");
  }

  @include smallTablet {
    background-image: url("../images/design-assets/parallax-sm-marketing-energy.jpg");
  }

  @include tablet {
    background-image: url("../images/design-assets/parallax-marketing-energy.jpg");
  }

  @include laptop {
    background-image: url("../images/design-assets/parallax-marketing-energy.jpg");
  }

  @include largeScreen {
    background-image: url("../images/design-assets/parallax-marketing-energy-large.jpg");
  }

  @include extraLargeScreen {
    background-image: url("../images/design-assets/parallax-marketing-energy-large.jpg");
  }*/


  // background-attachment: fixed;
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;

}

.block5 {
  width: 100%;
}

.outerBlock {
  // height amended using javascript
  height: 200px;
}

.innerBlock {
  // height amended using javascript
  height: 200px;
}

.rightAlignBlockWhite {
  @include phone {
    @include fortBlockStyle ($fortWhite, $jumboH2, $jumboH2Weight, $jumboPara, $paraBlockWeight, $fortWhite, center, true);
  }
  @include fortBlockStyle ($fortWhite, $jumboH2, $jumboH2Weight, $jumboPara, $paraBlockWeight, $fortWhite, right, false);
}

.leftAlignBlockBlue2Col {
  @include phone {
    @include fortBlockStyle ($fortCyan, $jumboH2, $jumboH2Weight, $jumboPara, $paraBlockWeight, $fortBlack, center, true);
  }
  @include fortBlockStyle ($fortCyan, $jumboH2, $jumboH2Weight, $jumboPara, $paraBlockWeight, $fortBlack, left, true);
}

.leftAlignBlockRed {
  @include phone {
    @include fortBlockStyle ($fortWhite, $jumboH2, $jumboH2Weight, $jumboPara, $paraBlockWeight, $fortWhite, center, true);
  }
  @include fortBlockStyle ($fortWhite, $jumboH2, $jumboH2Weight, $jumboPara, $paraBlockWeight, $fortWhite, left, false);
}

.rightAlignBlockBlue {
  @include phone {
    @include fortBlockStyle ($fortCyan, $jumboH2, $jumboH2Weight, $jumboPara, $paraBlockWeight, $fortBlack, center, true);
  }
  @include fortBlockStyle ($fortCyan, $jumboH2, $jumboH2Weight, $jumboPara, $paraBlockWeight, $fortBlack, right, true);
}

// scales image for any image inside of the block classes
.blockImage {

  width: 280px;
  height: auto;

  @include tinyPhone {
    width: 200px;
    height: auto;
  }

  @include smallTablet {
    width: 240px;
    height: auto;
  }

  @include laptop {
    width: 400px;
  }

  @include largeScreen {
    width: 400px;
  }

  @include extraLargeScreen {
    width: 500px;
  }

}


.contactiPhone {
  padding-right: $gutterSmall;
  width: 50px!important;
  height: 50px!important;
  @include phone {
    width: 40px!important;
    height: 40px!important;
    padding: 0;
  }
}

.contactEmail {
  padding-right: $gutterSmall;
  width: 40px !important;
  height: 40px !important;

  @include phone {
    padding-right: 0px;
    //width: 30px!important;
    //padding: 10px!important;
  }
}

.contact {
  font-weight: $contactParaWeight;
  font-size: $contactPara;
  color: $fortCyan;

  @include extraLargeScreen {
    font-weight: $contactParaWeight;
    font-size: $jumboParaExtraLarge;
    color: $fortCyan;
  }
}

// ***********************************************
// *** END OF HOMEPAGE / BIG HERO LANDING PAGE ***
// ***********************************************

// **************
// *** FOOTER ***
// **************

#footerWrapper {
  position: relative;
  width: 100%;
  background-color: $fortCyan;
  margin-top: 100px;
  padding-top: $gutterSmall;
  padding-bottom: $gutterLarge;
}

.footerIcon {
  margin-top: -110px;
}

.leftFooterCol {
  @include phone {
    @include alignmentMixin(center);
  }
  @include smallTablet {
    @include alignmentMixin(center);
  }
  @include alignmentMixin(left);
}

.rightFooterCol {
  @include phone {
    @include alignmentMixin(center);
  }
  @include smallTablet {
    @include alignmentMixin(center);
  }
  @include alignmentMixin(right);
}

.contactIconFooter {
  padding-right: $gutterSmall;
  width: 40px!important;
  height: 40px!important;
  @include phone {
    width: 30px!important;
    height: 30px!important;
    padding: 0;
  }
  @include extraLargeScreen {
    width: 60px !important;
    height: 60px !important;
  }
}

.footerPara {
  @include extraLargeScreen {
    p {
      font-size: $contactParaExtraLargeScreen!important;
      font-weight: $contactParaWeight;
      color: $fortWhite;
    }
  }
  p {
    font-size: $contactPara;
    font-weight: $contactParaWeight;
    color: $fortWhite;
  }
  a {
    decoration: none;
    color: $fortWhite;
  }
}

.footerParaSmall {
  @include extraLargeScreen {
    p {
      font-size: $paraSmallExtraLargeScreen!important;
      font-weight: $paraSmallWeight;
      color: $fortWhite;
    }
  }
  p {
    font-size: $paraSmall;
    font-weight: $paraSmallWeight;
    color: $fortWhite;
  }
  a {
    decoration: none !important;
    color: $fortWhite;
  }
}

.footerSocialMedia {
  padding-top: $gutterSmall;
  padding-bottom: $gutterSmall;
  @include extraLargeScreen {
    img {
      width: 60px !important;
      height: 60px !important;
    }
  }
  img {
    width: 40px;
    height: 40px;
  }
}

#contactButtonWrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: $gutterSmall;
  width: 200px;
  height: 50px;
}

#contactButton {
  position: relative;
  bottom: $gutterMedium;
  text-align: right;
}

.contactImagePopUp {
  opacity: 0;
}


// ******************
// *** END FOOTER ***
// ******************

// ********************
// *** LANDING PAGE ***
// ********************

// top parallax on landing pages
#topParallaxWrapperLanding {

  height: 568px;
  position: absolute;
  width: 100%;
  top: 0;

  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: $fortPurple;

}

#mainHeadlineContainer {
  height: 568px;
}

.jumbotron2 {

  padding-top:    $gutterSmall;
  padding-bottom: $gutterSmall;
  margin-bottom: $gutterSmall;
  color: $jumbotron-color;
  background-color: transparent;
  height: auto;

  @include phone {
    h1,
    .h1 {
      font-size: $jumboH1Mob;
    }

    p {
      font-size: $jumboPara;
    }

  }

  @include smallTablet {

    h1,
    .h1 {
      font-size: $jumboH1Mob;
    }

    p {
      font-size: $jumboPara;
    }

  }

  @include tablet {

    h1,
    .h1 {
      font-size: $jumboH1Mob;
    }

    p {
      font-size: $jumboPara;
    }

  }

  @include laptop {
    h1,
    .h1 {
      font-size: $jumboH1;
    }

    p {
      font-size: $jumboPara;
    }
  }

  @include largeScreen {

    h1,
    .h1 {
      font-size: $jumboH1;
    }

    p {
      font-size: $jumboPara;
    }

  }

  @include extraLargeScreen {

    h1,
    .h1 {
      font-size: $jumboH1ExtraLarge;
    }

    p {
      font-size: $jumboParaExtraLarge;
    }
  }

  h1,
  .h1 {
    color: $fortWhite;
    font-weight: $jumboH1Weight;
    margin-top: $gutterSmall;
  }

  p {
    margin-bottom: ($jumbotron-padding / 2);
    font-weight: $jumboParaWeight;
    color: $fortWhite;
  }


  > hr {
    border-top-color: darken($jumbotron-bg, 10%);
  }

  .container &,
  .container-fluid & {
    border-radius: $border-radius-large; // Only round corners at higher resolutions if contained in a container
    padding-left:  ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }

  .container {
    max-width: 100%;
  }

  @media screen and (min-width: $screen-sm-min) {
    //padding-top:    ($jumbotron-padding * 1.6);
    //padding-bottom: ($jumbotron-padding * 1.6);

    .container &,
    .container-fluid & {
      padding-left:  ($jumbotron-padding * 2);
      padding-right: ($jumbotron-padding * 2);
    }

    /*h1,
    .h1 {
      font-size: $jumboH1Mob;
    }*/
  }
}

.articlePadding {
  padding-top: $gutterLarge;
}

.rightLandingCol {
  text-align: right;
  @include phone {
    text-align: center;
  }
  @include smallTablet {
    text-align: center;
  }
  @include tablet {
    text-align: center;
  }
  position: relative;
  right: 0;
  h3 {
    color: $fortWhite;
    font-weight: $secNavHeaderWeight;
    font-size: $secNavHeaderSize;
    width: 100%;
    background-color: $fortPurple;
    padding: $gutterSmall $gutterSmall;
    margin-bottom: 0;
  }
  h4 {
    color: $fortCyan;
    font-weight: $caseStudyHeaderWeight;
    font-size: $caseStudyHeaderSize;
    border-bottom: 1px solid $fortCyan;
    padding-bottom: $gutterExtraSmall;
    line-height: 140%;

  }
  p {
    font-weight: $caseStudyParaWeight;
    color: $fortBlack;
    font-size: $caseStudyParaSize;
    margin-bottom: $gutterExtraSmall;
  }
  img {
    max-width: 100%;
  }
  a {
    decoration: none;
    color: $fortCyan;
    font-weight: $caseStudyLinkWeight;
  }

  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
    width: 100%;
    padding: $gutterSmall $gutterSmall;
    border-bottom: 1px solid $fortCyan;
    p {
      font-weight: $secNavLinksWeight;
      color: $fortCyan;
      font-size: $secNavLinksSize;
      margin-bottom: 0;
    }

    a {
      decoration: none;
      color: $fortCyan;
    }

  }
  div {
    margin-bottom: $gutterMedium;
  }
}

.leftLandingCol {
  h1 {
    color: $fortCyan;
    font-size: ceil(($font-size-base * 2.6))!important;
    font-weight: 700;
    margin-top: $gutterSmall;
    line-height: 160%;
    @include largeScreen {
      font-size: ceil(($font-size-base * 3))!important;
    }
    @include extraLargeScreen {
      font-size: ceil(($font-size-base * 4.2))!important;
    }

  }

  h2 {
    color: $fortPurple;
    font-size: $articleHeaderSize;
    font-weight: $articleHeaderWeight;
    width: 100%;
    border-bottom: 1px solid $fortPurple;
    padding-bottom: $gutterSmall + 2;
    margin-bottom: $gutterExtraSmall;
    line-height: 160%;
  }

  h3 {
    color: $fortCyan;
    font-size: $articleH3;
    font-weight: $articleHeaderWeight;
    line-height: 160%;
  }

  h4 {
    color: $fortCyan;
    font-size: $articleH4;
    font-weight: $articleHeaderWeight;
    line-height: 160%;
  }

  p {
    font-size: $articleParasize;
    font-weight: $articleParaWeight;
    line-height: 200%;
    color: $fortBlack;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  a {
    color: $fortCyan;
    decoration: none;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: $gutterSmall;
    margin-bottom: $gutterExtraSmall;
    margin-left: auto;
    margin-right: auto;
  }

  blockquote {
    border-left: 5px solid $fortRed;
    border-top: 1px solid $fortGrey;
    border-right: 1px solid $fortGrey;
    border-bottom: 1px solid $fortGrey;

    p {
      color: $fortCyan;
      font-weight: $articleBlockQuoteFontWeight;
      font-size: $articleBlockQuoteFontSize;
    }

  }

  li {
    line-height: 200%;
    color: $fortBlack;
    font-size: $articleParasize;
    font-weight: $articleParaWeight;
  }
}

// *****************
// *** ANIMATION ***
// *****************

// any element with th fadeIn class is tweened in using TweenMax
.fadeIn {
  opacity: 1;
}

// **************************************
// *** SOCIAL MEDIA SHARE ARTICLE BAR ***
// **************************************

.h2ArticleHeader {
 color: $fortPurple;
 font-size: $articleHeaderSize;
 font-weight: $articleHeaderWeight;
 width: 100%;
 border-bottom: none!important;
 padding-bottom: 0!important;
 margin-bottom: 0!important;
 //line-height: 160%;
}

.socialBarRow {
 border-top: 1px solid $fortPurple;
 border-bottom: 1px solid $fortPurple;
 margin-right: 0;
 margin-left: 0;
 margin-bottom: $gutterSmall;
}

.socialBarLeft {
 display: flex;
 justify-content: flex-start;
 align-items: center;

 img {
  margin: $gutterExtraSmall $gutterExtraSmall $gutterExtraSmall 0;
  width: 100px;
  height: auto;
 }

 div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start!important;
  align-items: center;
  padding-left: $gutterExtraSmall;
  p {
   line-height: 120%;
   font-size: $authorText;
   font-weight: $authorTextWeight;
   color: $fortPurple;
  }
  span {
   font-weight: $authorBold;
  }
 }

}

.socialBarNarrowCols {
 padding: 0;
}

.socialBarRight {
 @include phone {
  min-height: auto;
  justify-content: space-evenly;
  img {
   width: 70px;
   height: auto;
  }
 }
 @include tablet {
  min-height: auto;
  justify-content: flex-start;
 }

 @include smallTablet {
  min-height: auto;
  justify-content: flex-start;
 }
 min-height: 110px;
 display: flex;
 justify-content: flex-end;
 align-items: center;
 img {
  padding: $gutterExtraSmall;
  margin: 0;
 }
}

.fm-footer-sub {
  color: $fortWhite;
}
@charset "utf-8";


/* CUSTOM VARIABLES */
// all variables for bootstrap have been set in the file _fortvariables.scss
// the bootstrap.scss file that has all the import statements in has also been amended in order
// to uncomment the import of _variables.scss and replace it with _fortvariables.scss

/* any style customization must be before the bootstrap import */
@import "bootstrap-sprockets";
@import "bootstrap";
@import "custom"; // located in the _sass folder
// fix for icons... see https://github.com/twbs/bootstrap-sass/issues/746
@font-face {
font-family: 'Glyphicons Halflings';
src: url(#{$icon-font-path}#{$icon-font-name}.ttf) format('truetype');
src: url(#{$icon-font-path}#{$icon-font-name}.eot?#iefix)
format('embedded-opentype'),
url(#{$icon-font-path}#{$icon-font-name}.woff) format('woff'),
url(#{$icon-font-path}#{$icon-font-name}.ttf) , format('truetype'),
url(#{$icon-font-path}#{$icon-font-name}.svg##{$icon-font-svg-id})
format('svg');
}
